import { spacing, grey, white } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { ProductDetailsCardData } from '@ecomm/copy/helpers/types';
import { Link } from '@ecomm/internationalize-ui';

export const ShopButton: React.FC<
  React.PropsWithChildren<{ productCard: ProductDetailsCardData }>
> = ({ productCard }) => {
  const { trackEvent } = useTracking();

  const trackShopButtonClicked = () => {
    trackEvent({
      event: TrackingEvent.ClickedNavigation,
      properties: {
        category: productCard?.key,
        linkTo: productCard?.secondaryCta?.url ?? productCard?.primaryCta?.url,
        linkName: `Hamburger Menu - ${
          productCard?.secondaryCta?.text ?? productCard?.primaryCta?.text
        }`,
      },
    });
  };
  return (
    <StyledNavLink
      data-test-id={`${productCard?.key}.secondary`}
      to={productCard?.secondaryCta?.url ?? productCard?.primaryCta?.url}
      href={productCard?.secondaryCta?.url ?? productCard?.primaryCta?.url}
      onClick={trackShopButtonClicked}
    >
      <StyledLabel>
        {productCard?.secondaryCta?.text ?? productCard?.primaryCta?.text}
      </StyledLabel>
    </StyledNavLink>
  );
};

const StyledNavLink = styled(Link)`
  margin: 0 !important;
  backdrop-filter: blur(12px);
  background: ${grey[70]};
  border-radius: 100px;
  display: flex;
  justify-content: center !important;
  gap: ${spacing[8]};
  transition: all 300ms ease-in-out;
  width: 100%;
  outline: none;

  &:hover {
    background-color: ${white};
    p {
      color: ${grey[90]};
    }
  }
`;

const StyledLabel = styled.p`
  display: flex;
  font-weight: 600;
  height: 44px;
  align-items: center;
  font-size: 0.938rem;
  color: ${white};
`;
