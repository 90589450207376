import React from 'react';
import styled from 'styled-components';
import { useExtLinkEnv } from '@peloton/external-links/context/Provider';
import { media, hoverTransition } from '@peloton/styles';
import logAction from '@ecomm/logging/logAction';
import IdMeSvg from './IdMeSvg';
import { generateIdMeAuthorizationLink } from './utils';

const IdMeButton = () => {
  const externalLinks = useExtLinkEnv();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    logAction('ID.me Verification - Clicked ID.me Button');
    location.href = generateIdMeAuthorizationLink(externalLinks);
  };

  return (
    <StyledButton
      type="button"
      id="idme-wallet-button"
      data-test-id="idme-wallet-button"
      onClick={handleClick}
    >
      Verify with
      <IdMeSvg />
    </StyledButton>
  );
};

export default IdMeButton;

export const IDME_BUTTON_COLOR_MAP = {
  DEFAULT: '#08833D',
  HOVER: '#30975C',
  ACTIVE: '#06602D',
};

const StyledButton = styled.button`
  height: 40px;
  background-color: ${IDME_BUTTON_COLOR_MAP.DEFAULT};
  width: 100%;
  border-radius: 4px;
  border: 3px solid transparent;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;

  ${media.tabletLarge`
    width: 220px;  
    height: 48px;
  `};

  ${hoverTransition({
    property: 'background-color',
    to: IDME_BUTTON_COLOR_MAP.HOVER,
  })}
  &:active {
    background-color: ${IDME_BUTTON_COLOR_MAP.ACTIVE};
  }
  &:focus {
    background-color: ${IDME_BUTTON_COLOR_MAP.HOVER};
    border: 3px solid ${IDME_BUTTON_COLOR_MAP.DEFAULT};
  }
`;
