import { black, brand } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import type { PropsWithChildren, RefObject } from 'react';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled, { css, keyframes } from 'styled-components';
import { useHasVariation } from '@peloton/split-testing/hooks';
import {
  HAMBURGER_NAV_EXPERIMENT,
  HAMBURGER_NAV_VARIATION_ONE,
  HAMBURGER_NAV_VARIATION_TWO,
} from '@acme-ui/nav/constants';
import { TrackingEvent } from '@ecomm/analytics/models';
import type {
  ProductDetailsCardData,
  ProductDetailsData,
  ProductNavData,
} from '@ecomm/copy/helpers/types';
import { LinkButton } from '@ecomm/internationalize-ui';
import useFilteredProductCards from '../../hooks/useFilteredProductCards';
import ProductCard from './ProductCard';
import SecondaryLinks from './SecondaryLinks';
import SingleProductCtaSection from './SingleProductCtaSection';
import { useCompareButton } from './useCompareButton';

type MegaMenuProps = {
  wrapperRef?: RefObject<HTMLDivElement>;
  label: string;
  data: Pick<ProductNavData, 'key' | 'products' | 'secondaryLinks'>;
  realignLastChild: boolean;
  className?: string;
};

const MegaMenu: React.FC<React.PropsWithChildren<PropsWithChildren<MegaMenuProps>>> = ({
  wrapperRef,
  label,
  data: { key, products, secondaryLinks },
  realignLastChild,
  className = '',
}) => {
  const filteredProductCards: ProductDetailsData[] = useFilteredProductCards(products);
  const showSingleProductCtaSection =
    filteredProductCards.length === 1 && !secondaryLinks;

  const isDesktopHamburgerNavVariation1 = Boolean(
    useHasVariation(HAMBURGER_NAV_EXPERIMENT, HAMBURGER_NAV_VARIATION_ONE),
  );

  const isDesktopHamburgerNavVariation2 = Boolean(
    useHasVariation(HAMBURGER_NAV_EXPERIMENT, HAMBURGER_NAV_VARIATION_TWO),
  );

  const isDesktopHamburgerNavVariation =
    isDesktopHamburgerNavVariation1 || isDesktopHamburgerNavVariation2;

  const shouldShowSecondaryLinks =
    Boolean(secondaryLinks) && !isDesktopHamburgerNavVariation;

  const {
    shouldShowCompareButton,
    compareButtonText,
    compareButtonUrl,
    compareButtonTarget,
  } = useCompareButton({ navKey: key, secondaryLinks });

  const { trackEvent } = useTracking();

  const trackCompareLinksClicked = () => {
    trackEvent({
      event: TrackingEvent.ClickedNavigation,
      properties: {
        category: key,
        linkTo: compareButtonUrl,
        linkName: compareButtonText,
      },
    });
  };

  return (
    <StyledMegaMenu
      data-test-id={key}
      data-element-id={key}
      ref={wrapperRef}
      aria-label={label}
      enforceMinHeight={Boolean(secondaryLinks) && filteredProductCards.length === 2}
      hasSecondaryLinks={shouldShowSecondaryLinks}
      realignLastChild={realignLastChild}
      className={className}
      hasAccessoriesOffset={key === 'nav.accessories'}
    >
      <FlexWrapper>
        <CardContainer hasSecondaryLinks={Boolean(secondaryLinks)}>
          {filteredProductCards.length > 0 ? (
            <>
              {filteredProductCards.map(productData => (
                <ProductCard
                  key={productData.productName}
                  categoryId={key}
                  data={productData as ProductDetailsCardData}
                  showCtas={shouldShowSecondaryLinks || isDesktopHamburgerNavVariation}
                  hasSecondaryLinks={shouldShowSecondaryLinks}
                />
              ))}
            </>
          ) : null}
        </CardContainer>
        {isDesktopHamburgerNavVariation && shouldShowCompareButton && (
          <ButtonContainer>
            <LinkButton
              variant="outline"
              size="small"
              color="dark"
              width="fixed"
              to={compareButtonUrl}
              href={compareButtonUrl}
              target={compareButtonTarget ? '_blank' : undefined}
              onClick={trackCompareLinksClicked}
            >
              {compareButtonText}
            </LinkButton>
          </ButtonContainer>
        )}
        {showSingleProductCtaSection && (
          <SingleProductCtaSection categoryId={key} products={products} />
        )}
      </FlexWrapper>
      {shouldShowSecondaryLinks && (
        <SecondaryLinks categoryId={key} secondaryLinks={secondaryLinks} />
      )}
    </StyledMegaMenu>
  );
};

export default MegaMenu;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledMegaMenu = styled.div<{
  enforceMinHeight: boolean;
  hasSecondaryLinks: boolean;
  realignLastChild: boolean;
  hasAccessoriesOffset?: boolean;
}>`
  color: ${black};
  position: absolute;
  top: 58px;
  left: 50%;
  transform: translate(
    ${({ hasAccessoriesOffset }) => (hasAccessoriesOffset ? '-9rem' : '-50%')},
    0
  );
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  box-shadow: 0 30px 80px 0 ${rgba(black, 0.37)};
  background-color: #ffffff;
  min-height: ${({ enforceMinHeight }) => (enforceMinHeight ? '29rem' : '0')};
  min-width: ${({ hasSecondaryLinks }) => (hasSecondaryLinks ? '50rem' : '27.875rem')};
  animation: ${fadeIn} 600ms;
  ${({ realignLastChild }) =>
    realignLastChild
      ? css`
          &:last-child {
            right: 0;
            left: unset;
            transform: none;
          }
        `
      : ''};
`;

const FlexWrapper = styled.span`
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div<{ hasSecondaryLinks: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: ${({ hasSecondaryLinks }) =>
    hasSecondaryLinks ? '3.5rem 4.5rem 4rem 4rem' : '3rem 4rem 2.5rem 4rem'};
`;

const ButtonContainer = styled.div`
  background-color: ${brand.light};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
`;
