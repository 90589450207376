import type { AxiosInstance } from 'axios';
import { IDME_CLIENT_ID } from '@peloton/app-config';
import type { ExtLinkEnv } from '@peloton/external-links';
import { toHref, toWWWLink } from '@peloton/external-links';
import logAction from '@ecomm/logging/logAction';
import { ID_ME_USER_DATA_REQUEST } from './constants';

export const generateIdMeAuthorizationLink = (extLinkEnv: ExtLinkEnv) => {
  const redirectUri = getRedirectUri(extLinkEnv);
  const scopes = 'medical,military,nurse,responder,student,teacher';

  // TODO ECSHOP-1280: update IDME_CLIENT_ID env variable with stage client ID after ID.me final review
  const url = `https://groups.id.me/?client_id=${IDME_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scopes=${scopes}`;
  return url;
};

export const getRedirectUri = (extLinkEnv: ExtLinkEnv) => {
  return toHref(toWWWLink('/idme/callback'), extLinkEnv);
};

export const exchangeAuthCodeForUserData = async (
  restClient: AxiosInstance,
  externalLinks: ExtLinkEnv,
  code: string,
) => {
  try {
    const payload = {
      code: code,
      client_id: IDME_CLIENT_ID,
      redirect_uri: getRedirectUri(externalLinks),
      grant_type: 'authorization_code',
    };
    const response = await restClient.post(ID_ME_USER_DATA_REQUEST, payload);
    return response.data;
  } catch (e) {
    logAction('failed to retrieve ID.me user data');
  }
};
