import {
  Icon,
  grey,
  spacing,
  Flex,
  lime,
  Eyebrow,
  white,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { theUserIsInAustria } from '@peloton/internationalize';
import { useLocale } from '@peloton/next/hooks/useLocale';
import useNav from '@content/client/www/nav/useNav';
import Accordion from '@ecomm/accordion';
import type { AccordionHeaderStateProps } from '@ecomm/accordion';
import type {
  ProductDetailsCardData,
  ProductDetailsData,
} from '@ecomm/copy/helpers/types';
import type { ProductNavMeta } from '@ecomm/header/models';
import { LinkButton } from '@ecomm/internationalize-ui';
import useFilteredProductCards from '../hooks/useFilteredProductCards';
import { ProductCard } from '../ProductsMenu/HamburgerNav/ProductCard';
import SecondaryLinks from './SecondaryLinks';

type Props = {
  data: ProductNavMeta;
  handleRequestClose: () => void;
  className?: string;
};

const MenuItemWithAccordion: React.FC<React.PropsWithChildren<Props>> = ({
  data: { cmsId },
  handleRequestClose,
  className = '',
}) => {
  const locale = useLocale();
  const { content, isLoading } = useNav(locale);
  const { trackEvent } = useTracking();

  const entry = content?.subnavs.find((subnav: any) => subnav.key === cmsId);

  const filteredProductCards: ProductDetailsData[] = useFilteredProductCards(
    entry?.products || [],
  );
  const showSingleProductCtaSection = filteredProductCards.length === 1;

  const { key, title, ...nav } = entry;
  const hasSecondaryLinks = !!entry?.secondaryLinks;
  const url = nav.products?.[0]?.primaryCta.url;

  if (isLoading) {
    return null;
  }

  if (!entry) {
    return null;
  }

  if ((cmsId as string) === 'nav.apparel' || theUserIsInAustria(locale)) {
    return (
      <>
        <MenuLinkItem className={className}>
          <ButtonChevronContainer>
            <StyledLinkButton
              data-test-id={key}
              href={url}
              text={title}
              color="dark"
              onClick={() => {
                trackEvent({
                  properties: {
                    category: key,
                    linkName: title,
                    linkTo: url,
                  },
                });
              }}
            />
          </ButtonChevronContainer>
        </MenuLinkItem>
        <Divider />
      </>
    );
  }

  if ((cmsId as string) === 'nav.deals') {
    return (
      <>
        <MenuLinkItem className={className}>
          <HighlightedButtonContainer alignItems="center">
            <StyledLinkButton
              data-test-id={key}
              href={url}
              text={title}
              color="dark"
              onClick={() => {
                trackEvent({
                  properties: {
                    category: key,
                    linkName: title,
                    linkTo: url,
                  },
                });
              }}
            />
          </HighlightedButtonContainer>
        </MenuLinkItem>
        <Divider />
      </>
    );
  }

  return (
    <li className={className} data-test-id={key}>
      <StyledAccordion
        name={title}
        renderHeader={MenuItemAccordionHeader(title, () => {
          trackEvent({
            properties: {
              category: key,
              linkName: title,
              parent: 'DesktopHamburgerNav',
            },
          });
        })}
      >
        <StyledFlex
          flexDirection="row"
          flexWrap={(cmsId as string) === 'nav.accessories' ? 'wrap' : 'nowrap'}
          gap={spacing[24]}
          hasSecondaryLinks={hasSecondaryLinks}
        >
          {filteredProductCards.map((productData: ProductDetailsCardData) => (
            <ProductCard
              productCard={productData}
              showSingleProductCtaSection={showSingleProductCtaSection}
              key={productData?.key}
            />
          ))}
        </StyledFlex>
        {hasSecondaryLinks && (
          <SecondaryLinks
            categoryId={cmsId}
            secondaryLinks={entry.secondaryLinks}
            handleClose={handleRequestClose}
          />
        )}
      </StyledAccordion>
      <Divider />
    </li>
  );
};

const MenuItemAccordionHeader = (
  title: string,
  trackClick: () => void,
  // eslint-disable-next-line react/display-name
) => ({ isOpen }: AccordionHeaderStateProps) => (
  // eslint-disable-next-line styled-components-a11y/click-events-have-key-events
  <ButtonChevronContainer role="button" tabIndex={0} onClick={trackClick}>
    <StyledEyebrow size="large" textColor={white}>
      {title}
    </StyledEyebrow>
    <StyledChevron
      name="chevron"
      primaryColor={grey[50]}
      aria-hidden={true}
      rotate={isOpen ? 90 : 270}
      height={12}
    />
  </ButtonChevronContainer>
);

export default MenuItemWithAccordion;

const StyledAccordion = styled(Accordion)`
  padding: ${spacing[12]} 0;
`;

const MenuLinkItem = styled.li`
  padding: ${spacing[12]} 0;
`;

const StyledChevron = styled(Icon)`
  margin-right: ${spacing[8]};
  transition: all 0.4s ease-in-out 0s;
`;

const ButtonChevronContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    color: ${grey[50]} !important;
  }
`;

const StyledEyebrow = styled(Eyebrow)`
  &:hover {
    color: ${grey[50]} !important;
  }
  ${ButtonChevronContainer}:hover & {
    color: ${grey[50]} !important;
  }
`;

const StyledLinkButton = styled(LinkButton)`
  width: 100% !important;
  padding: unset !important;

  &:hover {
    background-color: ${grey[90]} !important;
    border-color: transparent !important;
    color: ${grey[50]};
  }
`;

const HighlightedButtonContainer = styled(Flex)`
  color: ${lime[50]} !important;

  a {
    color: currentColor !important;
  }
`;

const Divider = styled.hr`
  background-color: ${grey[70]};
  border: none;
  height: 1px;
  width: 100%;

  &.nav-panel-item {
    opacity: 0;
  }
`;

const StyledFlex = styled(Flex)<{
  hasSecondaryLinks: boolean;
}>`
  ${({ hasSecondaryLinks }) =>
    !hasSecondaryLinks &&
    `
      padding: 0 0 ${spacing[32]} 0 !important;
  `}
`;
