import { Container, Media, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import type { ProductDetailsCardData } from '@ecomm/copy/helpers/types';

const PRODUCT_IMAGE_DIMENSIONS = {
  mobile: spacing[120],
  desktop: '178px',
};

export const ProductImage: React.FC<
  React.PropsWithChildren<Pick<ProductDetailsCardData, 'image'>>
> = ({ image: { title, url } }) => {
  const cachedImage = toCloudinarySrc({ src: url, width: 178 });
  return (
    <Container height={PRODUCT_IMAGE_DIMENSIONS} width={PRODUCT_IMAGE_DIMENSIONS}>
      <Media
        media={{
          alt: title ?? 'Product Card Image',
          mobile: cachedImage ?? '',
          tablet: cachedImage ?? '',
          desktop: cachedImage ?? '',
          type: 'image',
        }}
        width="178px"
      />
    </Container>
  );
};
