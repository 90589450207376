import { Container, Flex, grey, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { ProductDetailsCardData } from '@ecomm/copy/helpers/types';
import { Link } from '@ecomm/internationalize-ui';
import { ShopButton } from './ShopButton';
import { ProductCardDirection } from './types';

type ProductCardContainerProps = {
  direction: ProductCardDirection;
  productCard: ProductDetailsCardData;
  showProductCard?: boolean;
  showShopButton?: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isHovered: boolean;
};

export const ProductCardContainer: React.FC<
  React.PropsWithChildren<ProductCardContainerProps>
> = ({
  children,
  direction,
  productCard,
  showProductCard,
  showShopButton,
  onMouseEnter,
  onMouseLeave,
  isHovered,
}) => {
  const { trackEvent } = useTracking();

  const trackProductCardClicked = () => {
    trackEvent({
      event: TrackingEvent.ClickedNavigation,
      properties: {
        category: productCard?.key,
        linkTo: productCard?.primaryCta?.url ?? productCard?.secondaryCta?.url,
        linkName: 'Product Cards - Hamburger',
      },
    });
  };

  return (
    <Container
      width={
        !showProductCard
          ? {
              mobile: '172px',
              desktop: '240px',
            }
          : 'auto'
      }
    >
      <Flex flexDirection="column" gap={spacing[24]}>
        {showProductCard && (
          <StyledLink
            to={productCard?.primaryCta?.url ?? productCard?.secondaryCta?.url}
            href={productCard?.primaryCta?.url ?? productCard?.secondaryCta?.url}
            onClick={trackProductCardClicked}
            hasUnderline={false}
            data-test-id={`${productCard.key}.productCardLink`}
          >
            <StyledProductCardOverlay
              padding={
                direction === ProductCardDirection.Horizontal
                  ? {
                      mobile: `${spacing[24]} 20px`,
                      desktop: `${spacing[24]} ${spacing[64]} ${spacing[24]} 20px`,
                    }
                  : {
                      mobile: `${spacing[24]} ${spacing[16]}`,
                      desktop: `${spacing[24]} 20px`,
                    }
              }
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              direction={direction}
              isHovered={isHovered}
              borderRadius={spacing[16]}
            >
              <Flex
                flexDirection={
                  direction === ProductCardDirection.Horizontal ? 'row' : 'column'
                }
                alignItems={
                  direction === ProductCardDirection.Horizontal ? 'center' : undefined
                }
                gap={
                  direction === ProductCardDirection.Horizontal ? spacing[48] : undefined
                }
              >
                {children}
              </Flex>
            </StyledProductCardOverlay>
          </StyledLink>
        )}
        {showShopButton && <ShopButton productCard={productCard} />}
      </Flex>
    </Container>
  );
};

const StyledProductCardOverlay = styled(Container)<{
  direction: ProductCardDirection;
  isHovered: boolean;
}>`
  transition: all 300ms ease-in-out;
  background: ${({ direction, isHovered }) => {
    if (direction === ProductCardDirection.Horizontal) {
      if (isHovered) {
        return `radial-gradient(221.66% 110.18% at 18.7% 35.4%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.00) 100%), ${grey[75]}`;
      } else {
        return `radial-gradient(221.66% 110.18% at 18.7% 35.4%, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), ${grey[80]}`;
      }
    } else {
      if (isHovered) {
        return `radial-gradient(109.76% 126.09% at 28.8% 23.55%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.00) 100%), ${grey[75]}`;
      } else {
        return `radial-gradient(109.76% 126.09% at 28.8% 23.55%, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), ${grey[80]}`;
      }
    }
  }};
`;

const StyledLink = styled(Link)`
  margin: 0 !important;
`;
