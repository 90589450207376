import { Display, Flex, Label, spacing, white } from '@pelotoncycle/design-system';
import React from 'react';
import { startCase } from '@peloton/text';
import type { ProductDetailsCardData } from '@ecomm/copy/helpers/types';
import { ProductCardDirection } from './types';

type ProductCardTextProps = {
  direction: ProductCardDirection;
  productCard: any;
};

const filterProductName = (productCard: ProductDetailsCardData) =>
  productCard?.key !== 'nav.productCard.GiftCard'
    ? startCase(productCard?.productName.split(' ')[1] || productCard?.productName)
    : startCase(productCard?.productName);

export const ProductCardText: React.FC<React.PropsWithChildren<ProductCardTextProps>> = ({
  direction,
  productCard,
}) => {
  return (
    <Flex
      flexDirection="column"
      gap={
        direction === ProductCardDirection.Horizontal
          ? spacing[8]
          : { mobile: spacing[4], desktop: spacing[8] }
      }
      minWidth={direction === ProductCardDirection.Horizontal ? '180px' : '178px'}
    >
      <Display size="small" textColor={white}>
        {filterProductName(productCard)}
      </Display>
      <Label size="small" textColor="white">
        {productCard.description}
      </Label>
    </Flex>
  );
};
